<template>
  <div class="video-wrapper">
    <div class="video-box" @mouseenter="getComponentCursor($event, 'play')"
      @mouseleave="getComponentCursor($event, null)">
      <div class="layer"></div>
      <video muted autoplay loop playsinline>
        <source :src="$get(contentData, 'data.list.0.short_video.videoFile.url')" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="play  pointer-target" @click="playVideo">
      </div>
    </div>

    <teleport to='#fullscrean-video'>
      <transition name="fade" :duration="1500">
        <div class="full-screen-video" @mouseenter="getComponentCursor($event, 'zoom-cancel')"
          @mouseleave="getComponentCursor($event, null)" v-if="fullScreenVideo" key="fullVideo">
          <video loop controls ref="videoRef">
            <source :src="$get(contentData, 'data.list.0.video.videoFile.url')" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          <div class="close pointer-target" @click="closeVideo">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="1.86731" width="1.7657" height="41.1998" transform="rotate(-45 0 1.86731)" fill="black" />
              <rect x="29.1326" y="0.618774" width="1.7657" height="41.1998" transform="rotate(45 29.1326 0.618774)"
                fill="black" />
            </svg>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useMenuStore } from "@/store/pinia/menu";


export default {
  name: 'Video_box',
  props: ['contentData'],
  data() {
    return {
      fullScreenVideo: false
    }
  },
  methods: {
    ...mapActions(useMenuStore, ["getComponentCursor"]),
    playVideo() {
      this.fullScreenVideo = true;
      setTimeout(() => {
        this.$refs.videoRef.play();
      }, 500)
    },
    closeVideo() {
      this.$refs.videoRef.pause();
      this.fullScreenVideo = false;
    },
    handlePause() {
      this.$refs.videoRef.pause();
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s cubic-bezier(.33, 1, .68, 1);
}

.fade-leave-active {
  transition-delay: 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.fade-enter-active video,
.fade-leave-active video {
  transition: 0.5s cubic-bezier(.33, 1, .68, 1);
}

.fade-enter-active video {
  transition-delay: 0.5s;
}

.fade-enter-from video,
.fade-leave-to video {
  opacity: 0;
}


.pointer-target {
  // cursor: none;
}

.video-wrapper {
  margin-bottom: -1px;
  margin-top: 233px;
}

.full-screen-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background-color: #242424;

}

.video-box {
  position: relative;
  padding-top: 60.1%;
  clip-path: polygon(0% 6.5%, 16.04% 6.5%, 16.04% 0%, 98.5% 0%, 98.5% 7.1%, 100% 7.1%, 100% 100%, 11.54% 100%, 11.54% 96.89%, 9.63% 96.89%, 9.63% 94.46%, 0% 94.46%);

  video {
    clip-path: polygon(0% 6.5%, 16.04% 6.5%, 16.04% 0%, 98.5% 0%, 98.5% 7.1%, 100% 7.1%, 100% 100%, 11.54% 100%, 11.54% 96.89%, 9.63% 96.89%, 9.63% 94.46%, 0% 94.46%);

  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  z-index: 10;
  pointer-events: none;
}

.play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 60px;

  svg {
    display: none;
  }
}


@media screen and(max-width: 1900px) {
  .video-box {
    padding-top: 59.7%;
  }

  .video-wrapper {
    margin-top: 160px;
  }
}

@media screen and(max-width: 1439px) {
  .video-wrapper {
    margin-top: 143px;
  }
}

@media screen and(max-width: 1279px) {
  .video-wrapper {
    margin-top: 82px;
  }
}

@media screen and(max-width: 767px) {
  .video-wrapper {
    margin-top: 65px;
  }

  .video-box {
    position: relative;
    padding-top: 0;
    height: 529px;
    clip-path: polygon(0% 2.07%, 50.4% 2.07%, 50.4% 0%, 100% 0%, 100% 97.93%, 49.6% 97.93%, 49.6% 100%, 0% 100%);


    video {
      clip-path: polygon(0% 2.07%, 50.4% 2.07%, 50.4% 0%, 100% 0%, 100% 97.93%, 49.6% 97.93%, 49.6% 100%, 0% 100%);
    }
  }

  .layer {
    clip-path: polygon(0% 2.07%, 50.4% 2.07%, 50.4% 0%, 100% 0%, 100% 97.93%, 49.6% 97.93%, 49.6% 100%, 0% 100%);
  }

  .close {
    width: 52px;
    height: 52px;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    bottom: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    cursor: pointer;

    svg {
      display: flex;
    }
  }

  .fade-enter-active .close,
  .fade-leave-active .close {
    transition: 0.5s cubic-bezier(.33, 1, .68, 1);
  }

  .fade-enter-active .close {
    transition-delay: 0.5s;
  }

  .fade-enter-from .close,
  .fade-leave-to .close {
    opacity: 0;
  }
}
</style>